import dayjs from "dayjs";
import { ReferenceDetailType, ReferencesFilterType } from "../types/dataTypes";

export const PAGE_LIMIT = 100;
export const PAGE_TITLE = "Integration Internal Database App";

export const GLOBAL_SIZES = { HEADER: 50 }

export const CSS = { MAX_WIDTH: "640px" }

export const initialFormData: ReferenceDetailType = {
    ProjectID: 0,
    LangVerID: 0,
    ProjectNo: '',
    FocusOn: '',
    ProjectTitle: '',
    ProjectTitle_short: '',
    Country: '',
    Location: '',
    Region: '',
    short: '',
    FieldOfService: '',
    country_homepage: '',
    OriginOfFunding: '',
    StartDay: '',
    StartDate: '',
    EndDay: '',
    CompletionDate: '',
    ProjectValue: '',
    ProportionLegalEntity: '',
    NoOfStaff: '',
    NoOfPersonMonth: '',
    Personnel: '',
    Personnel_EU_EOI: '',
    AssociatedFirms: '',
    Consortium: '',
    Lead: false,
    SeniorStaff: '',
    ProjectResults: '',
    ProjectBackground: '',
    ProjectObjectives: '',
    ProjectActivities: '',
    ServicesProvided: '',
    ImplementationFeatures: '',
    ProjectDescription_short: '',
    extrashort_GTZ_PQ: '',
    KeyWords: '',
    Picture: '',
    Category: '',
    zuständig: '',
    ClientContact: '',
    ProjectValueEuro: '',
    ProjectValueDollar: '',
    Folder: '',
    NewRef: false,
    INTEE: false,
    CoP: false,
    variant_name: '',
    variant_description: '',
    ClientID: 0,
    Homepage: false,
    Bemerkungen: '',
    ConstructionServicesEUR: 0,
    ConstructionServicesDollar: 0,
    ProjectDescription_short_german: '',
    address: '',
    NoOfPersonMonthsByAssociatedFirms: '',
    Textvariante1: '',
    Textvariante2: '',
    Textvariante3: '',
    NoOfPersonMonthINT: '',
    ProjectDescription_detailed: '',
    extrashort_GTZ_PQ_german: '',
  };

  // Initial state for filters
export const initialFilters: ReferencesFilterType = {
    completionDate: dayjs().subtract(5, 'year'),
    countries: [],
    selectedCompany: 'both',
    projectValueEuro: 50000,
    fieldsOfServices: [],
    refNumTitles: [],
    fullTextProjectNumberList: [],
    printSelectionList: [],
  };

  export const MetaDataReferences = [
    {database: 'references', tables:['FieldOfService','Client']},
    {database: 'experts', tables:['language','region','donor']},
  ]

  export const WorkExperienceOptions = [
    "0-5 years",
    "5-10 years",
    "10-15 years",
    "15-20 years",
    "> 20 years",
  ]

  export const GenderOptions = [
    "Woman",
    "Man",
    "Non-binary",
    "Unknown",
  ]

  export const ReferencesTableDataColumns = ["ProjectNo", "ProjectTitle", "Client", "Country", "ProjectValueEuro", "StartDate", "CompletionDate",
    "INTEE", "Homepage", "FieldOfService"]