import * as XLSX from 'xlsx';


export function formatCurrency(value: number) {
    if (value >= 1000000) {
        return `${(value / 1000000).toFixed(2)} Mio`;
    } else if (value >= 1000) {
        return `${Math.floor(value / 1000)} k`;
    } else {
        return value;
    }
}
//utility function to format date in the format dd/mm/yyyy
export function formatDate(date: string) {
    return new Date(date).toLocaleDateString('en-CA');
}

export function formatCurrencyWithValueAndUnit(value: number) : { value: number , unit: string } {
    if (value >= 1000000) {
        return { value: parseFloat((value / 1000000).toFixed(2)), unit: 'Mio' };
    } else if (value >= 1000) {
        return { value: Math.floor(value / 1000), unit: 'k' };
    } else {
        return { value, unit: '' };
    }
}


export function excelDownload(columnNames: string[], data: any[]) {
    // Convert data from array of objects to array of arrays
    const worksheetData = [
        columnNames,
        ...data.map(item => columnNames.map(column => item[column]))
    ];

    // Create a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Create a workbook and add the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Generate a Blob from the workbook
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

    // Create a link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'refences_short_all.xlsx';

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
}